/* eslint-disable import/no-cycle */
import Vue from 'vue';
import { auth } from '../../providers/services';
import router from '../../router';

const state = {
  access_token: localStorage.getItem('access_token') || '',
  menu: localStorage.getItem('menu') || [],
  defaultRoute: localStorage.getItem('defaultRoute') || '/main/buat-pesanan',
};

const getters = {
  isLoggedIn: (_state) => !!_state.access_token && !!_state.menu.length > 0,
  menu: (_state) => _state.menu,
  defaultRoute: (_state) => _state.defaultRoute,
};

const mutations = {
  set_token(store, data) {
    store.access_token = data;
    localStorage.setItem('access_token', data);
  },
  set_menu(store, data) {
    const menu = JSON.stringify(data.map((m) => {
      if (m.child && m.child.length) {
        return {
          ...m,
          url: '',
        };
      }
      return {
        ...m,
      };
    }));
    store.menu = menu;
    localStorage.setItem('menu', menu);
  },
  set_defaultRoute(store, data) {
    store.defaultRoute = data;
    localStorage.setItem('defaultRoute', data);
  },
  logout(store) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('menu');
    localStorage.removeItem('defaultRoute');
    store.access_token = '';
    store.menu = '';
  },
};

const actions = {
  async login({ commit, dispatch }, data) {
    try {
      const loginRes = await auth.login(data);
      if (loginRes.data.menu && loginRes.data.menu.length === 0) {
        return Vue.prototype.$dialog.notify.error('terjadi kesalahan. menu tidak ditemukan silahkan hubungi admin', {
          timeout: 5000,
        });
      }
      commit('set_token', loginRes.data.token);
      if (loginRes.data.menu) {
        commit('set_menu', loginRes.data.menu);
        if (loginRes.data.menu.length === 0) {
          return loginRes;
        }
        if (loginRes.data.menu[0].child.length > 0) {
          commit('set_defaultRoute', loginRes.data.menu[0].child[0].url);
        } else {
          commit('set_defaultRoute', loginRes.data.menu[0].url);
        }
      } else {
        commit('set_menu', []);
      }
      return loginRes;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  logout({ commit }, responseStatus) {
    commit('logout');
    commit('user/reset_my_user_info', null, { root: true });
    if (responseStatus === 403) return router.go('/welcome/login');
    if (router.currentRoute.path !== '/welcome/login') {
      router.replace('/welcome');
    }
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
