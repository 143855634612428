/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import * as Strings from '../assets/strings';

const Welcome = () => import(/* webpackChunkName: "view-Welcome" */ '../views/Welcome.vue');
const Login = () => import(/* webpackChunkName: "component-WelcomeLogin" */ '../components/Welcome/Login.vue');
const ActivationAccount = () => import(
  /* webpackChunkName: "component-WelcomeActivation" */ '../components/Welcome/Activation.vue'
);
const ActivationSubAccount = () => import(
  /* webpackChunkName: "component-WelcomeActivation" */ '../components/Welcome/SubActivation.vue'
);
const ForgotPassword = () => import(
  /* webpackChunkName: "component-ForgotPassword" */ '../components/Welcome/ForgotPassword.vue'
);
const ResetPassword = () => import(
  /* webpackChunkName: "component-ResetPassword" */ '../components/Welcome/ResetPassword.vue'
);

const MainContainer = () => import(/* webpackChunkName: "MainContainer" */ '../views/MainContainer');
const Registration = () => import(/* webpackChunkName: "component-CustomerList" */ '../components/Welcome/Registration.vue');

const UserAdminPage = () => import(/* webpackChunkName: "view-UserAdmin" */ '../views/UserAdmin.vue');
const UserAdminList = () => import(/* webpackChunkName: "component-UserAdminList" */ '../components/UserAdmin/List');
const UserAdminCreate = () => import(/* webpackChunkName: "component-UserAdminCreate" */ '../components/UserAdmin/Create');

const Order = () => import(/* webpackChunkName: "view-Order" */ '../views/Order.vue');

const Home = () => import(/* webpackChunkName: "component-OrderHome" */ '../components/Order/Home.vue');

const Create = () => import(
  /* webpackChunkName: "component-OrderCreateCreate" */ '../components/Order/Create/Create.vue'
);

const CompanyRegistrationPage = () => import(/* webpackChunkName: "view-CompanyRegistration" */ '../views/CompanyRegistration.vue');
const DocumentSupportCompany = () => import(
  /* webpackChunkName: "view-DocumentSupportCompany" */ '../views/DocumentSupportCompany.vue'
);

// Legal Document
const LegalDocumentPage = () => import(/* webpackChunkName: "view-LegalDocumentPage" */ '../views/LegalDocument.vue');
const LegalDocumentList = () => import('../components/LegalDocument/List.vue');

// Status Order
const StatusOrderPage = () => import('../views/StatusOrder');
const ListStatusOrder = () => import('../components/StatusOrder');
const OrderDetail = () => import('../components/StatusOrder/OrderDetails');
const StatusHistory = () => import('../components/StatusOrder/StatusHistory.vue');
const TrackingShipment = () => import('../components/StatusOrder/Tracking.vue');
// const OrderBasp = () => import('../components/StatusOrder/Basp');

// Approval
const InboxApprovalPage = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../views/ApprovalInbox.vue');
const InboxApprovalList = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../components/Approval/Inbox');
const ApprovalInProcessPage = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../views/ApprovalInProcess.vue');
const ApprovalInProcessList = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../components/Approval/InProcess');
const CompletedApprovalPage = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../views/CompleteApproval.vue');
const CompletedApprovalList = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../components/Approval/Completed');
const StatusApprovalPage = () => import(/* webpackChunkName: "view-StatusApproval" */ '../views/StatusApproval.vue');
const StatusApprovalList = () => import(
  /* webpackChunkName: "component-StatusApprovalList" */ '../components/CompanyRegistration/StatusRegistration.vue'
);

// MOU
const MoUPage = () => import(/* webpackChunkName: "view-MoUPage" */ '../views/MoU.vue');
const MouDetailIndex = () => import(/* webpackChunkName: "component-MouDetailIndex" */ '../components/MoU');
const MoUDetail = () => import(/* webpackChunkName: "component-MoUDetail" */ '../components/MoU/Detail');

const MouApproval = () => import(/* webpackChunkName: "component-MouApproval" */ '../components/MouInProcess');

// Workflow
const SkeletonLoadersWorkflow = () => import('../views/SkeletonLoadersWorkflow.vue');

// AccountInfo
const AccountInfo = () => import(/* webpackChunkName: "view-AccountInfo" */ '../views/AccountInfo.vue');
const AccountInfoGet = () => import(
  /* webpackChunkName: "component-AccountInfoGet" */ '../components/AccountInformation/List.vue'
);

// Report
const ReportPage = () => import('../views/Report.vue');
const ReportList = () => import(/* webpackChunkName: "component-ReportList" */ '../components/Report');
const ReportFiltres = () => import(/* webpackChunkName: "component-ReportList" */ '../components/Report/ReportFilters');
const ReportDetail = () => import(/* webpackChunkName: "component-ReportList" */ '../components/Report/Detail');

const AboutPage = () => import(/* webpackChunkName: "view-About" */ '../views/About');
const ContactPage = () => import(/* webpackChunkName: "view-About" */ '../views/ContactUs');
const PrivacyPage = () => import(/* webpackChunkName: "view-PrivacyPage" */ '../views/PrivacyPolicy');

// Email
const Email = () => import(/* webpackChunkName: "view-Email" */ '../views/Email.vue');
const EmailList = () => import(/* webpackChunkName: "component-EmailList" */ '../components/Email/List.vue');

// Contract
const ContractPage = () => import(/* webpackChunkName: "view-ContractPage" */ '../views/Contract');
const ContractForm = () => import(/* webpackChunkName: "component-ContractForm" */ '../components/Contract');

Vue.use(VueRouter);

// guard for logged in user to not open welcome page
const loginPageGuard = (to, from, next) => {
  if (store.getters['auth/isLoggedIn']) {
    next('/main');
  } else {
    next();
  }
};

const privateRoute = (to, from, next) => {
  if (
    process.env.VUE_APP_API_TARGET === 'development'
    || process.env.VUE_APP_API_TARGET === 'mock'
  ) return next();
  const { fullPath } = to;
  const replacePath = (_path, toIndex) => `/${_path
    .split('/')
    .splice(1, toIndex - 1)
    .toString()
    .replace(/,/g, '/')}`;
  let selectedMenu;
  const navs = JSON.parse(localStorage.getItem('menu'));
  navs.forEach((nav) => {
    if (new RegExp(`^${nav.url}$`).test(replacePath(fullPath, nav.url.split('/').length))) {
      selectedMenu = nav;
    }
    if (nav.child) {
      nav.child.forEach((navChild) => {
        if (
          navChild.url
          && new RegExp(`^${navChild.url}$`).test(
            replacePath(fullPath, navChild.url.split('/').length),
          )
        ) {
          selectedMenu = navChild;
        }
      });
    }
  });
  if (selectedMenu) return next();
  return next(store.getters['auth/defaultRoute']);
};

const authGuard = (to, from, next) => {
  if (store.getters['auth/isLoggedIn']) {
    store
      .dispatch('user/fetchMyInfo')
      .then((res) => {
        if (!res) {
          throw new Error('not allowed');
        }
        return next();
      })
      .catch((err) => {
        if (err.message === 'not allowed' || err.response.status === 403) {
          store.dispatch('auth/logout', 403);
        }
      });
  } else {
    next('/welcome/login');
  }
};

const routes = [
  {
    path: '/welcome',
    component: Welcome,
    beforeEnter: loginPageGuard,
    children: [
      {
        path: '',
        redirect: '/welcome/login',
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          title: 'Masuk',
        },
      },
      {
        path: 'activation-account',
        name: 'activation-account',
        component: ActivationAccount,
        meta: {
          title: 'Aktivasi Akun',
        },
      },
      {
        path: 'activation-sub-account',
        name: 'activation-sub-account',
        component: ActivationSubAccount,
        meta: {
          title: 'Aktivasi Sub Akun',
        },
      },
      {
        path: 'registration',
        name: 'registration',
        component: Registration,
        meta: {
          title: 'Registration',
        },
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
          title: 'Lupa Password',
        },
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
          title: 'Reset Password',
        },
      },
      {
        path: 'privacy-policy',
        component: PrivacyPage,
        meta: {
          title: 'Ketentuan Privasi',
        },
      },
    ],
  },
  {
    path: '/main',
    component: MainContainer,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        redirect: store.getters['auth/defaultRoute'],
      },
      {
        path: 'buat-pesanan',
        component: Order,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/buat-pesanan/home',
          },
          {
            path: 'home',
            name: 'Home',
            component: Home,
            meta: {
              headerTitle: 'Pesanan Baru',
              title: 'Pesanan Baru',
            },
          },
          {
            path: 'create/:isBasp?',
            name: 'create',
            component: Create,
            title: 'Create',
            props: true,
            meta: {
              headerTitle: 'Pesanan Baru',
              title: 'Buat Pesanan',
            },
          },
        ],
      },
      {
        path: 'mou',
        component: MoUPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/mou/index',
          },
          {
            path: 'index',
            name: 'index-mou-detail',
            meta: {
              headerTitle: Strings.mou.MOU,
              title: 'Detail MoU',
            },
            component: MouDetailIndex,
          },
          {
            path: 'detail',
            name: 'mou-detail',
            meta: {
              headerTitle: Strings.mou.MOU,
              title: 'Detail MoU',
            },
            component: MoUDetail,
          },
        ],
      },
      {
        path: 'user-admin',
        component: UserAdminPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/user-admin/list',
          },
          {
            path: 'list',
            name: 'user-admin-list-page',
            meta: {
              headerTitle: Strings.userAdmin.PAGE_NAME,
              title: 'List User Admin',
            },
            component: UserAdminList,
          },
          {
            path: 'create',
            name: 'user-admin-create-page',
            meta: {
              headerTitle: Strings.userAdmin.PAGE_NAME,
              title: 'Buat User Admin',
            },
            component: UserAdminCreate,
          },
          {
            path: 'edit/:companyId/:userId',
            name: 'user-admin-edit-page',
            meta: {
              headerTitle: Strings.userAdmin.PAGE_NAME,
              title: 'Edit user admin',
            },
            component: UserAdminCreate,
          },
        ],
      },
      {
        path: 'company-registration',
        name: 'user-admin-company-registration-page',
        component: CompanyRegistrationPage,
        beforeEnter: privateRoute,
        meta: {
          headerTitle: Strings.companyRegistration.REGISTRATION_PAGE,
          title: 'Registrasi Perusahaan',
        },
      },
      {
        path: 'registration',
        component: StatusApprovalPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/registration/list',
          },
          {
            path: 'list',
            name: 'status-registration-list',
            component: StatusApprovalList,
            meta: {
              headerTitle: Strings.companyRegistration.REGISTRATION_PAGE,
              title: 'Status Registrasi',
            },
          },
          {
            path: 'company-registration',
            name: 'company-registration-page',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.companyRegistration.REGISTRATION_PAGE,
              title: 'Registrasi Perusahaan',
            },
          },
        ],
      },
      {
        path: 'dokumen-legal',
        component: LegalDocumentPage,
        children: [
          {
            path: '',
            redirect: '/main/dokumen-legal/list',
          },
          {
            path: 'list',
            name: 'Document Legal',
            component: LegalDocumentList,
            meta: {
              headerTitle: Strings.legalDocument.PAGE_NAME,
              title: 'Dokumen Legal',
            },
          },
        ],
      },
      {
        path: 'status-pesanan',
        component: StatusOrderPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/status-pesanan/list/needconfirmation',
          },
          {
            path: 'list/:tab',
            component: ListStatusOrder,
            name: 'order-status-list',
            meta: {
              headerTitle: Strings.statusOrder.PAGE_NAME,
              title: 'Status Pesanan',
            },
          },
          {
            // path: 'detail/:fromPage/:title/:latestStatus/:id/:serviceType?/:isBasp?',
            path: 'detail/:id',
            component: OrderDetail,
            name: 'order-status-detail',
            meta: {
              headerTitle: Strings.statusOrder.PAGE_NAME,
              title: 'Detail Status',
            },
          },
          {
            path: 'status/:id',
            name: 'Status-History',
            component: StatusHistory,
            meta: {
              title: 'Status',
            },
            props: true,
          },
          {
            path: 'tracking/:id',
            name: 'Tracking-Shipment',
            component: TrackingShipment,
            meta: {
              title: 'Lacak Pesanan',
            },
            props: true,
          },
        ],
      },
      {
        path: 'approval-inbox',
        component: InboxApprovalPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/approval-inbox/list',
          },
          {
            path: 'list',
            name: 'approval-inbox',
            component: InboxApprovalList,
            meta: {
              headerTitle: Strings.approval.APPROVAL_PAGE_NAME,
            },
          },
          {
            path: 'company-registration/:id/:defWorkflowId',
            name: 'inbox-company-registration',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.approval.INBOX,
              title: 'Registrasi Perusahaan',
            },
          },
          {
            path: 'document-support/:id/:defWorkflowId',
            name: 'inbox-document-support',
            component: DocumentSupportCompany,
            meta: {
              headerTitle: Strings.approval.INBOX,
              title: 'Dokumen Pendukung Perusahaan',
            },
          },
          {
            path: 'mou/:id/:defWorkflowId',
            name: 'inbox-mou',
            component: MouApproval,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Mou',
            },
          },
        ],
      },
      {
        path: 'approval-process',
        component: ApprovalInProcessPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/approval-process/list',
          },
          {
            path: 'list',
            name: 'approval-process',
            component: ApprovalInProcessList,
            meta: {
              headerTitle: Strings.approval.APPROVAL_PAGE_NAME,
            },
          },
          {
            path: 'company-registration/:id/:defWorkflowId',
            name: 'process-company-registration',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Registrasi Perusahaan',
            },
          },
          {
            path: 'document-support/:id/:defWorkflowId',
            name: 'process-document-support',
            component: DocumentSupportCompany,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Dokumen Pendukung Perusahaan',
            },
          },
          {
            path: 'mou/:id/:defWorkflowId',
            name: 'process-mou',
            component: MouApproval,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Mou',
            },
          },
        ],
      },
      {
        path: 'complete-approval',
        component: CompletedApprovalPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/complete-approval/list',
          },
          {
            path: 'list',
            name: 'complete-approval',
            component: CompletedApprovalList,
            meta: {
              headerTitle: Strings.approval.APPROVAL_PAGE_NAME,
            },
          },
          {
            path: 'company-registration/:id/:defWorkflowId',
            name: 'complete-company-registration',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'Registrasi Perusahaan',
            },
          },
          {
            path: 'document-support/:id/:defWorkflowId',
            name: 'complete-document-support',
            component: DocumentSupportCompany,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'Dokumen Pendukung Perusahaan',
            },
          },
          {
            path: 'mou/:id/:defWorkflowId',
            name: 'complete-mou',
            component: MouApproval,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'Mou',
            },
          },
        ],
      },
      {
        path: 'approval/:page/:id/:defWorkflowId',
        name: 'skeleton-loaders',
        component: SkeletonLoadersWorkflow,
        meta: {
          headerTitle: '',
        },
      },
      {
        path: 'account',
        component: AccountInfo,
        children: [
          {
            path: '',
            redirect: '/main/account/info',
          },
          {
            path: 'info',
            name: 'account-info',
            meta: {
              headerTitle: '',
              title: 'Informasi Akun',
            },
            component: AccountInfoGet,
          },
        ],
      },
      {
        path: 'laporan',
        component: ReportPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/laporan/list',
          },
          {
            path: 'list',
            name: 'report-list',
            component: ReportList,
            meta: {
              title: 'Daftar Laporan',
              headerTitle: 'Laporan',
            },
          },
          {
            path: 'filter/:id',
            name: 'report-filter',
            meta: {
              title: 'Parameter Laporan',
            },
            component: ReportFiltres,
          },
          {
            path: 'detail/:id',
            name: 'report-detail',
            meta: {
              title: 'Detail Laporan',
            },
            component: ReportDetail,
          },
        ],
      },
      {
        path: 'about',
        component: AboutPage,
        meta: {
          headerTitle: 'Tentang Kami',
          title: 'Tentang Kami',
        },
      },
      {
        path: 'contact-us',
        component: ContactPage,
        meta: {
          headerTitle: 'Hubungi Kami',
          title: 'Hubungi Kami',
        },
      },
      {
        path: 'privacy-policy',
        component: PrivacyPage,
        meta: {
          header: 'Ketentuan Privasi',
          title: 'Ketentuan Privasi',
        },
      },
      {
        path: 'email',
        component: Email,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/email/list',
          },
          {
            path: 'list',
            name: 'Email',
            component: EmailList,
            meta: {
              headerTitle: Strings.email.emailDatabase,
              title: 'Email',
            },
          },
        ],
      },
      {
        path: 'kontrak',
        component: ContractPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/kontrak/detail',
          },
          {
            path: 'detail',
            meta: {
              headerTitle: 'Kontrak',
              title: 'Detail Kontrak',
            },
            component: ContractForm,
          },
        ],
      },
      {
        path: '/*',
        redirect: store.getters['auth/defaultRoute'] || '/main/buat-pesanan',
      },
    ],
  },
  {
    path: '*',
    beforeEnter: authGuard,
    redirect: '/welcome/login',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  document.title = to.meta.title || 'Shipper Dashboard';
  next();
});

export default router;
