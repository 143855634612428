export default {
  ORDER_NO: 'No Kiriman',
  DELIVERY_DATE: 'Tanggal Pengiriman',
  CREATE_DATE: 'Tanggal Buat',
  ORIGIN: 'Lokasi Asal',
  DESTINATION: 'Lokasi Tujuan Akhir',
  ETA: 'ETA',
  DESC_TARIF: 'Ket Tarif',
  ORDER_NUMBER: 'No Pesanan',
  CUSTOMER_NAME: 'Nama Customer',
  MULTIPICK: 'Multipick',
  MULTIDROP: 'Multidrop',
  NO_GRUP_CARGO: 'No Grup Kargo',
  NO_CARGO: 'No Kargo',
  TRANSPORTER: '3PL',
  TRANSPORTER_NAME: 'Nama 3PL',
  DELIVERY_DATE_ORIGIN: 'Tgl Pengiriman',
  DELIVERY_DATE_DESTINATION: 'Tgl Berangkat',
  NO_AVIATION_SERVICE: 'No. Pelayanan Penerbangan',
  SHIPPER: 'Shipper',
  SHIPPER_INFO: 'Informasi Shipper',
  TRIP_INFO: 'Informasi Perjalanan',
  RUTE_INFO: 'Informasi Rute',
  VEHICLE_INFO: 'Informasi Kendaraan',
  MULTIMODA_INFO: 'Informasi Multi Moda',
  VEHICLE: 'Kendaraan',
  VEHICLE_NUMBER: 'No. Kendaraan',
  LABEL3PL: 'Rekanan 3PL',
  LAST_LOCATION: 'Lokasi Terakhir',
  STATUS: 'Status',
  SEARCHNO: 'Cari No. Kiriman/Pesanan/Cargo',
  FILTER: 'Filter Berdasarkan',
  PAGE_NAME: 'Daftar Pesanan Hari Ini',
  MERGE: 'Gabungkan',
  HAVE_NOT_BEEN_ASSIGNED: 'Belum diassign satupun',
  PARTIALLY_ASSIGNED: 'Ada sebagian yang belum diassign',
  ASSIGN1: 'Assign / Ubah 3PL',
  CHANGE_RUTE: 'Ubah Rute',
  ADD_RUTE: 'Tambah Rute',
  SEND_DATE: 'Tanggal Pengirim',
  TYPE_VEHICLE: 'Tipe Kendaraan',
  PICK_UP_LOCATION: 'Lokasi Penjemputan',
  LOCATION_IN_ACCEPTED: 'Lokasi Bongkar',
  MERGE_ORDER_LCL: 'Gabungkan Pesanan LCL',
  COMBINED_ORDERS: 'Pesanan yang sudah digabungkan',
  NOT_COMBINED_ORDERS: 'Pesanan belum digabungkan',
  TONASE: 'Tonase',
  KUBIKASI: 'Kubikasi',
  FILTER_PROVINSI_ORIGIN: 'Filter Provinsi Asal',
  FILTER_PROVINSI_DESTINATION: 'Filter Provinsi Tujuan',
  SELECT: 'Pilih',
  CANCEL_ORDER: 'Batalkan Pesanan',
  ACTION: 'Aksi',
  ASSIGN_MULTI_MODA: 'Assign Rekanan 3PL - Multi Moda',
  PLEASE_SELECT: 'Silahkan pilih terlebih dahulu',
  UPDATE_STATUS: 'Update Status',
  CHANGE_TRANSPORTER: 'Ubah Rekanan 3PL & Data Multi Moda',
  ALERT_DELETE_MULTIMODA: 'Apakah anda yakin ingin membatalkan Kiriman ini ?',
  NOTE: 'Catatan',
  STATUS_NOW: 'Status Saat Ini',
  NEW_STATUS: 'Status Saat Ini',
  BACKHAULING: 'Backhauling',
  ACCEPT_ORDER: 'Terima Pesanan',
  REJECT_ORDER: 'Tolak Pesanan',
  LIST_COST: 'List biaya ongkos kirim',
  UPDATE_INFORMATION: 'Ubah / Update Informasi',
  TYPE_MODA: 'Tipe Moda',
  LUMPSUM: 'Lumpsum',
  CRUISE_NUMBER: 'No.Pelayaran Penerbangan',
  RITASE: 'Ritase',
  KILO: 'Kilo',
  ONCALL: 'Oncall',
  BOX: 'Box',
  QUANTITY_INFO: 'Informasi Quantity',
  DRIVER_NAME: 'Nama Sopir',
  CAR_TYPE: 'Tipe Mobil',
  NO_TRANSPORTER: 'No Kendaraan',
  CONTACT_DRIVER: 'Kontak Sopir',
  FILTER_PROVINCE: 'Filter Provinsi',
  TARIF_INFO: 'Keterangan Tarif',
  SHIPPER_NAME: 'Nama Shipper',
  NAME_SENDER: 'Nama Pengirim',
  CATEGORY: 'Kategory',
  SUB_CATEGORY: 'Sub Kategori',
  PHONE_NUMBER_SENDER: 'No Telepon Pengirim',
  LABEL_STATUS_ORDER: 'Status Pesanan',
  DOCUMENTATION: 'Dokumentasi',
  COMPLETED_DOCUMENT: 'Dokumen Lengkap',
  FOTO_LATTER: 'Foto Surat Jalan',
  ADD_DOCUMENT_FOTO: 'Tambah Foto Dokumen',
  LOCATION_MAP: 'Peta Lokasi Asal dan Tujuan Pesanan',
  COMPLATE_THE_ORDER: 'Selesaikan Pesanan',
  CANCEL_THE_ORDER: 'Batalkan Pesanan',
  CONFIRMED: 'Terkonfirmasi',
  CARGO_STATUS: 'Status Kargo',
  CALCULATE_COST: 'Hitung Biaya',
  MAIN_COST: 'Biaya Utama (Rp)',
  ADDITIONAL_COST: 'Biaya Tambahan (Rp)',
  AMOUNT_OF_COSTS: 'Jumlah Biaya (Rp)',
  TOTAL_COST: 'Total Biaya (Rp)',
  TOTAL_BILL: 'Total Tagihan (Rp)',
  GOODS_RECEIVED_WAREHOUSE: 'Barang Diterima Gudang',
  CALCULATE: 'Hitung',
  CHANGE_QUANTITY_ORDER: 'Mengubah quantity untuk order',
  NO_ROAD_LETTER: 'No Surat Jalan',
  QUANTITY: 'Quantity',
  UNIT: 'Unit',
  COMPLATED_THE_ORDER: 'Menyelesaikan Pesanan',
  LABEL_CANCEL_ORDER: 'Apakah anda yakin ingin mebatalkan Pesanan ini?',
  OVERWRITE_CONFIRMATION_SHIPPER: 'Overwrite konfirmasi Shipper',
  LABEL_GOODS_RECEIVED_WAREHOUSE: 'Apakah anda yakin barang diterima Gudang?',
  STATUS_ORDER: {
    CANCEL: 'Dibatalkan',
    CONFIRM: 'Terkonfirmasi',
  },
  BASP_LOCATION: 'Lokasi BASP',
  BASP_NOTE: 'Catatan BASP',
  BASP_TITLE: 'Alasan BASP',
  BASP_REASON: 'Alasan BASP',
  EXPLANATION_OF_REASON: 'Keterangan Alasan',
  EXPLANATION_OF_REASON_BASP: 'Alasan Keterangan BASP',
  TYPE_OF_GOODS: 'Jenis Barang',
  DESCRIPTION: 'Keterangan',
  PROOF_OF_DIFFERENCE_IN_ACCEPTANCE: 'Bukti Selisih Penerimaan',
  GOODS_CATEGORY: 'Kategori Barang',
  GOODS_SUB_CATEGORY: 'Sub Kategori Barang',
  RECEPIENTS_NAME: 'Nama Penerima',
  SIGNATURE: 'Tanda Tangan',
  DELIVERY_COMPLETE: 'Penerimaan Selesai',
  DAMAGE_REPORT: 'Laporan Kerusakan / Selisih',
  REPORT_DIFFERENCE: 'Laporkan Selisih',
};
